.lock-position {
  // overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
}

.mudal {
  top: 0;
  position: absolute;
  //overflow:auto;
  -webkit-overflow-scrolling: touch;
  right: 0;
  transition: all 0.2s linear;
  left: @side-menu-width;
  height: 100%;
  min-height: 100%;

  &[data-ref='brand-redirection'] {
    z-index: 50 !important;
  }

  @media screen and (min-width: 1151px) {
    .horizontal-toggle & {
      &[data-parent='account'] {
        height: calc(100% - 120px);
        min-height: calc(100% - 120px);
        top: initial;
        bottom: 0;
      }
    }
  }

  @media (max-width: 1150px) {
    left: 0;
    top: 25px;
  }
}

.mudal-shade {
  .mudal();

  position: fixed;
  top: -50%;
  height: 200%;
  background: #000;
  left: 0;
  opacity: 0;
  -webkit-overflow-scrolling: auto;

  &[data-modal-ref='brand-redirection'] {
    z-index: 49 !important;
  }

  @media (max-width: 1150px) {
    top: 0;
  }
}

.mudal-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: table;
}

.mudal-content {
  height: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  #funds-protection {
    max-width: 500px;
  }
}

.mudal-dialog {
  text-align: left;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
  background: white;
  .rizk-border-radius;
  position: relative;
  margin: 20px auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s linear;
  opacity: 1;
  color: @modal-paragraph;
  max-width: 666px;

  #redirect-counter {
    text-align: center;
    padding-top: 0px;
    font-size: 24px;
  }

  &.all-consents {
    height: 90vh;
    width: 90vw;
    overflow: scroll;
    max-width: 600px;
    max-height: 600px;
    display: flex;
    flex-direction: column;

    // header {
    //   flex: 1 1 auto;
    // }

    .tc-notification-container {
      max-height: 80%;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding: 0px 32px;
    }

    label {
      line-height: 20px;
    }

    .accept-terms-txt-wrap {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      .consentsAcceptForm {
        height: 100%;
      }
    }
    .tc-buttons-wrapper {
      background-color: #fff;
      width: 100%;
      padding-bottom: 10px;
      flex: 1 1 auto;

      button {
        margin: 10px 0px;
        background: var(--continue-btn-bg);
      }
    }
  }

  > div {
    padding: 32px;
  }

  header {
    padding: 0 68px 0 32px;
    background: #e2e8f0;
    min-height: 72px;
    h1 {
      line-height: 40px;
      padding: 16px 0;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: #000;
    }
  }

  h1,
  h2,
  h3 {
    color: #000;
  }

  h1,
  h2 {
    margin: 0;
    text-transform: uppercase;
  }

  h1 {
    .header-font(40px);
  }

  h2 {
    .header-font(24px);
  }

  h3 {
    .header-font(16px);

    text-transform: none;
    margin: 4px auto;
  }

  p {
    margin: 0 auto 24px auto;
  }

  .info {
    margin: 18px auto 18px auto;
  }

  input {
    width: 100%;
    margin: 4px auto 16px;
    color: #000;
    border: 1px solid @grey;
  }

  button {
    width: 100%;
    margin: 24px 0 16px;
    background: #292929;
    color: #fff;
    border-radius: var(--button-border-radius);

    &:disabled {
      opacity: 0.5;
    }

    &.btn-green {
      .btn-green;
    }

    &.login-btn {
      background: var(--login-modal-btn-bg);
    }
    .loader {
      display: inline-block;
      width: 15px;
      height: 15px;
      border: 2px solid #fff;
      border-radius: 50%;
      border-top: 2px solid transparent;
      animation: spin 1s linear infinite;
      vertical-align: middle;
  }
  
  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  

    &.btn-continue {
      .btn-continue;
    }

    &.btn-red {
      .btn-red;
    }
  }

  &.green {
    header {
      background: var(--modal-header-bg);
      h1 {
        color: white;
      }
    }

    > div button:not('.game-providers-grid-btn') {
      background: @button-green;
      &:hover,
      &:focus {
        background: @button-green-hover;
      }
    }
  }
}

.mudal-dialog-info {
  header {
    margin-top: -20px;
  }
}

.info-modal-buttons {
  display: flex;
  button {
    margin: 20px 10px 0 10px;
    width: 100%;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 650px) {
  .mudal-dialog-self-exclusion {
    max-width: 380px;
  }
}

.modal-close {
  cursor: pointer;
  border-radius: 0;
  padding: 0;
  margin: 0;
  background: url('../img/icons/modal-close-button.svg');
  width: 40px;
  height: 40px;
  opacity: 1;
  z-index: 10;

  &:hover {
    opacity: 0.8;
  }
}

#modal-payment header .modal-back {
  cursor: pointer;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 20px 30px 16px 0px;
  background-color: rgba(0, 0, 0, 0.2);
  &:before {
    content: '<';
    font-size: 18px;
    color: #fff;
    position: absolute;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }
}

@media screen and (max-width: 650px) {
  #modal-payment header .modal-back {
    margin: 20px 20px 16px -20px;
  }
}

.close-fill,
.mudal button[name='close'] {
  position: absolute;
  right: 16px;
  top: 16px;

  .modal-close;
}

.mudal-account {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100%;
}

.mudal-dialog-iframe iframe {
  .rizk-border-radius;
}

.registration-modal {
  max-width: 920px;
  margin: auto;
}

.games-filter-modal-heading {
  display: flex;
  background-color: #e6e3e3;
  justify-content: space-between;
}

.games-filter-modal-heading > h4 {
  color: black;
  padding-left: 5%;
  font-weight: 500;
}

.mob-game-providers-sort-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5%;
  border-bottom: 1px solid #9c999969;
  label {
    color: black;
  }
  input[type='radio'] {
    display: block !important;
    margin: unset !important;
    padding: unset !important;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    border: 1px solid #9191919e;
    z-index: -1;
    &:checked {
      background: var(--brand-green);
      box-shadow: inset 0px 0px 0px 4px #fff;
    }
  }
}

.game-providers-modal {
  max-width: 750px;
  margin: auto;
}

@media only screen and (min-width: 1023px) {
  #game-providers-modal {
    height: auto !important;
  }
}

@media screen and (max-width: 374px) {
  .game-providers-grid {
    padding-bottom: 30%;
  }

  .show-games-btn {
    border-top: 1.1px solid #1b1919;
  }
}

@media screen and (min-width: 375px) and (max-width: 640px) {
  .game-providers-grid {
    padding-bottom: 15%;
  }

  .show-games-btn {
    border-top: 1px solid #1b191926;
  }
}

@media screen and (max-width: 640px) {
  .game-providers-grid {
    padding-top: unset;
    padding-left: unset;
    padding-right: unset;
    .game-providers-grid-btn {
      font-size: 3.5vw;
    }
  }

  .show-games-btn {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    background: white;
    box-shadow: unset !important;
    border-right: 0;
  }

  .game-filters-container {
    position: fixed;
    width: 100%;
  }
}

@media screen and (min-width: 641px) {
  .game-providers-grid {
    padding: 3%;
    .game-providers-grid-btn {
      font-size: unset;
    }
  }
  .show-games-btn {
    padding: 5%;
  }
}

.game-filters-container {
  height: 100%;
  .sort-filter-parent {
    overflow-y: scroll;
    height: 70%;
  }
}

.game-providers-grid {
  height: fit-content;
  width: 100%;
  padding: 1%;
  overflow-y: scroll;
  .game-providers-grid-btn {
    background: #e6e8e7;
    border: none !important;
    color: black !important;
    display: inline-flex;
    width: fit-content !important;
    margin: 1%;
    &.active {
      background: var(--tertiary-color) !important;
    }
    button {
      width: unset !important;
      margin: unset !important;
      background: unset !important;
      color: unset !important;
      border-radius: unset !important;
      padding: 0;
      white-space: nowrap;
    }
  }
}

.game-providers-header {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #f3f2f2bf;
  label {
    text-transform: unset !important;
    color: #2e2d2d !important;
    letter-spacing: unset !important;
    font-size: 14px;
  }
}

.game-providers-header-clear {
  background: unset !important;
  width: unset !important;
  color: #04040482 !important;
  margin: 0 !important;
  padding: 0;
  text-decoration: underline;
}

.close-label-prov:after {
  display: inline-block;
  content: '\00d7'; /* This will render the 'X' */
}

.show-games-btn > button {
  background: var(--brand-green) !important;
  padding-left: 5%;
  padding-right: 5%;
  border: none;
}

.mobile-game-modal {
  max-width: 920px;
  margin: auto;
}

.login-links {
  margin: auto;
  margin-top: 10px;

  .left {
    float: left;
    @media screen and (max-width: 350px) {
      float: none;
      display: block;
    }
  }
  .right {
    float: right;
    @media screen and (max-width: 350px) {
      float: none;
    }
  }
}

.new-pass-wrap {
  position: relative;
  .help-block {
    display: none;
  }
  #toggle-password {
    position: absolute;
    right: 0;
    top: 0;
  }
}

#sectorwin {
  margin: 15px;
}

#accept-terms {
  .static-page {
    padding: 0 40px;
    button {
      width: 222px;
    }
  }
}

.modal-forgot-password {
  p {
    &.errors {
      color: @brand-red;
    }
  }

  .form-group.has-success {
    input {
      border: 1px solid var(--brand-green);
    }
  }
}

.mudal-bankid-email,
.mudal-bankid-auth {
  .form-email,
  .form-tel {
    &.valid {
      border: 1px solid var(--brand-green);
    }
    &.invalid {
      border: 1px solid @brand-red;
    }
  }
  .close-button {
    display: none;
  }
  #form_user_submit:disabled,
  #form_user_submit[disabled] {
    background: #4faf6499 !important;
  }
}

#inkabet-migration-form {
  #form_user_submit:disabled,
  #form_user_submit[disabled] {
    background: #4faf6499 !important;
  }
}

#deposit-receipt-modal {
  &:extend(.mudal-dialog all);

  background: #eceff4 !important;
  width: 24%;
  min-width: 460px;

  div {
    display: block;
    padding: 1%;
  }

  h2 {
    padding-bottom: 6%;
  }
  button {
    width: 40px;
    margin: 0;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+N0ZCNUMyNzQtQ0ZGMC00OEYzLUJCNjMtRTc4MTVFNjhCQTUzPC90aXRsZT48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IGZpbGwtb3BhY2l0eT0iLjI1IiBmaWxsPSIjMjcyNzI3IiB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHJ4PSIyMCIvPjxwYXRoIGQ9Ik0xOS45NSAyMi4wN2wtMy44OSAzLjg5YTEuNSAxLjUgMCAwIDEtMi4xMi0yLjEybDMuODg4LTMuODktMy44OS0zLjg5YTEuNSAxLjUgMCAwIDEgMi4xMjMtMi4xMmwzLjg5IDMuODg4IDMuODktMy44OWExLjUgMS41IDAgMCAxIDIuMTIgMi4xMjNsLTMuODkgMy44OSAzLjg5IDMuODlhMS41IDEuNSAwIDAgMS0yLjEyIDIuMTJsLTMuODktMy44OXoiIGZpbGw9IiNGRkYiLz48L2c+PC9zdmc+);
  }

  .payment-receipt-btn {
    width: 66% !important;
    margin: 5% 18% !important;
    background: #449746;
    color: #fff;
    border-radius: var(--button-border-radius);
  }

  span.title-text {
    font-size: 0.75em;
    color: #449746;
    font-weight: normal;
    font-size: 12px;
    /* font-family: Gibson; */
    letter-spacing: 0.08em;
    text-transform: uppercase;
    vertical-align: text-top;
  }

  .tick-icon {
    top: 15px;
    right: 6px;
    width: 24px;
    height: 24px;
    display: block;
    position: relative;
    position: relative;
    /* Green */
    background: url('../img/form-icons.png');
    background-size: 24px;
    transition: all 0.2s ease-in;

    float: initial;
    position: relative;
    display: block;
    margin-left: 49%;
    margin-bottom: 1%;
    transform: scale(1.5);
  }

  .receipt-container {
    width: 100%;
    height: 100%;
    left: 100%;
    top: 100%;
    background: #eceff4;
  }

  .label-value {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    display: flex;
  }

  .label-text {
    position: relative;
    left: 2%;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #7d7d7d;
  }

  .lead-message {
    display: block;
    padding-top: 9%;
    color: #449746 !important;
    padding-left: 1%;
  }

  .amount-value {
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 12px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 600;
    text-align: center;
    padding-top: 4%;
  }

  .print-receipt {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #0038ff;
    padding-left: 41%;
    text-align: center;
  }

  .deposit-succesful {
    display: block;
    position: relative;

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 1%;
    line-height: 18px;
    height: 30%;
    /* identical to box height */

    text-align: center;
  }

  .payment-receipt {
    background: #ffffff;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 3%;
    border-radius: 4px;
    width: 100%;
  }

  .transaction-id {
    position: initial;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5%;
    margin-top: 3%;
    padding: 2%;
    text-transform: uppercase;
    text-align: center;
    color: #000000;
  }

  .payment-receipt .transaction-id:first-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .payment-receipt .transaction-id:last-child {
    margin-top: 0;
    padding-top: 0;
  }

  .payment-date {
    display: flex;
    font-weight: normal;
    font-size: 0.9em;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 30%;
    color: #636466;
    float: right;
    vertical-align: text-top;
    flex-direction: column-reverse;
    padding-top: 0.4%;
  }

  .payment-method-logo-modal {
    width: 100%;
    padding-bottom: 6%;
    padding-top: 7%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-template-columns: 30% 70%;
  }

  .logo-transform {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 60px;
  }
}
@media screen and (max-width: 650px) {
  #deposit-receipt-modal {
    &:extend(.mudal-dialog all);

    background: #eceff4 !important;
    width: 90%;
    height: 89%;
    min-width: auto;

    h1 {
      font-size: 30px;
    }

    header {
      padding: 0 48px 0 9px;
    }

    div {
      display: block;
      padding: 1%;
    }

    h2 {
      padding-bottom: 6%;
    }

    .title-header {
      margin-bottom: 5%;
    }

    .tick-icon {
      margin-left: 49%;
      margin-bottom: 10%;
    }

    .payment-date {
      position: relative;
    }

    .transaction-id {
      font-size: 12px;
    }

    .label-value {
      left: -3%;
    }
  }
}

#hard-reality-check-countdown {
  text-align: center;
  padding-top: 0px;
  font-size: 24px;
}

#login-modal {
  .login-caps {
    position: absolute;
    right: 23px;
    height: 20px;
    margin: 12px;
    display: none;
  }
}

.modal-de-migration {
  #form_user_state {
    border-radius: 5px;
    border: 1px solid #dbdbdb;
  }
  #form_submit_migration {
    background-color: var(--brand-green);
  }
}

#unverified-block .btn-fieldset {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
  word-wrap: break-word;
}
#unverified-block .btn {
  padding: 15px 0;
  width: 90% !important;
  background: #009a39;
}
#unverified-block .btn-fieldset a {
  color: #fff;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  #unverified-block .btn-fieldset {
    justify-content: space-around;
    flex-direction: row;
  }
  #unverified-block .btn {
    width: 45% !important;
  }
}

#pep-block .btn-fieldset {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
  word-wrap: break-word;
}
#pep-block .btn {
  padding: 15px 0;
  width: 90% !important;
  background: #009a39;
}
#pep-block .btn-fieldset a {
  color: #fff;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  #pep-block .btn-fieldset {
    justify-content: space-around;
    flex-direction: row;
  }
  #pep-block .btn {
    width: 45% !important;
  }
}

#kyc-block .btn-fieldset {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
  word-wrap: break-word;
}
#kyc-block .btn {
  padding: 15px 0;
  width: 90% !important;
  background: #009a39;
}
#kyc-block .btn-fieldset a {
  color: #fff;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  #kyc-block .btn-fieldset {
    justify-content: space-around;
    flex-direction: row;
  }
  #kyc-block .btn {
    width: 45% !important;
  }
}
#doc-pending .notification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

#doc-pending .notification-container svg {
  width: 6rem;
}

#doc-pending .btn-fieldset {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  padding: 5px !important;
  word-wrap: break-word;
  width: 100%;
}
#doc-pending .green-btn {
  padding: 15px 0;
  width: 90% !important;
  background: #009a39;
}
#doc-pending .outline-btn {
  color: #292929;
  border: 1px solid #292929;
  padding: 15px 0;
  width: 90% !important;
  background: transparent;
}
#doc-pending .btn-fieldset a {
  color: #fff;
  text-decoration: none;
}
#doc-pending .btn-fieldset button {
  margin: 5px 0 16px;
}
#mudal-login {
  display: block;
}

#confirm-deposit .btn-fieldset button {
  margin: 5px 0 16px;
}

#confirm-deposit .btn-outline {
  background: transparent;
  color: #292929;
  border: 1px solid #292929;
  padding: 15px;
}

.brand-redirect-modal {
  z-index: 9999999;
  .close-button {
    display: none !important;
  }
  .brand-redirect-count {
    font-weight: bold;
    margin-left: 5px;
  }
}

#modal-de-annual-kyc {
  max-width: 920px;
}
